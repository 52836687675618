export class TipoTarifa {
  static Eixo = 'eixo'
  static Peso = 'peso'
  static Hora = 'hora'
  static Embalagem = 3
  static Subproduto = 4
  static HoraExcedente = 5
}

export class Charge {
  static End = 'end'
  static Start = 'start'
  static EndFixed = 'endFixed'
  static Range = 'range'
}

export class TipoPeso {
  static KG = "KG"
  static TON = "TON"
}

export class StatusAgendamento {
  static CANCELADO = "CANCELADO"
  static DEFAULT = "AGENDADO"
  static INICIAL = "TRIADO"
  static CONCLUIDO = "CONCLUÍDO"
  static ORIGEM = "RETORNOU A ORIGEM"
}

export default class TipoAgendamento {
  static Novo = 1
  static DoPedido = 2
  static Triagem = 3
  static DaProgramacao = 4
  static EdicaoTriado = 5
  static EdicaoAgendado = 6
}
